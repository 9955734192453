<template>
  <article>
    <div class="col-1 text-center">
      <h2>{{ $t('app-title') }}</h2>
      <p class="text-center">
        {{ $t('slogan') }}
      </p>
      <br>
      <div>
        <router-link
          class="el-button bg-gd-pm"
          to="login"
        >
          <fai icon="sign-in"/>

          {{ $t('login') }}
        </router-link>

        <router-link
          class="el-button bg-gd-sc"
          to="register"
        >
          <fai icon="file-signature"/>

          {{ $t('create-account') }}
        </router-link>
      </div>
    </div>
    <div class="col-2 d-flex justify-center">
      <el-button
        v-if="videoBtnShow"
        :class="videoBtnClass"
        :disabled="!videoPlayable"
        type="primary"
        @click="playPause"
        circle
      >
        <fai :icon="videoBtnIcon"/>
      </el-button>
      <video
        ref="video"
        poster="../../../assets/images/screenshot.png"
        src="../../../../temp/screen-capture.webm"
        @canplay="videoPlayable = true"
        @pause="videoPlaying = false"
        @play="videoPlaying = true"
        preload="auto"
        controls
      >
      </video>
    </div>
  </article>
</template>

<script>
export default {
  data() {
    return {
      videoPlaying: false,
      videoPlayable: false,
    };
  },

  computed: {
    videoBtnShow() {
      return this.$store.state.style.breakpoints['md-and-up'].matches;
    },
    videoBtnIcon() {
      if (this.videoPlaying) {
        return 'pause';
      }

      return 'play';
    },
    videoBtnClass() {
      if (this.videoPlaying) {
        return ['video-btn', 'playing'];
      }

      return ['video-btn'];
    },
  },

  methods: {
    playPause() {
      if (this.videoPlaying) {
        return this.$refs.video.pause();
      }

      return this.$refs.video.play();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/home/styles/var";

article {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: calc(100vh - #{ ($header-padding * 2) + $header-height });
  background-image: url("../../../assets/images/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

video {
  width: calc(100% - 60px);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  object-fit: cover;
}

h2 {
  font-size: 2em;
}

h2, p {
  color: $--color-text-primary;
}

.col-2 {
  position: relative;

  &:hover .video-btn.playing {
    display: block;
  }
}

.video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  border: 0;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  background: $gradient-secondary;

  &.playing {
    display: none;
  }
}

@media screen and (min-width: $--md) {
  h2, p {
    color: #fff;
  }

  video {
    height: 360px;
    width: auto;
  }
}
</style>
